<template>
  <div>
    <b-table
      ref="refRelationsTable"
      class="position-relative"
      :items="information"
      :fields="fields"
      primary-key="id"
      table-class="text-nowrap"
      responsive="sm"
      show-empty
      no-provider-filtering
      sticky-header="65vh"
      small
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(administrator)="data">
        <div
          v-for="(itemGeneral, indexGeneral) in JSON.parse(data.item.ad)"
          :key="`administrator-${indexGeneral}`"
        >

          <div
            v-if="itemGeneral.deleted_at === null"
            style="height: 20px!important;"
          >

            <li>
              <span class="m-1 predeName">
                {{ itemGeneral.name }}
              </span>

              <b-button
                v-b-tooltip.hover.top="'Edit ' + itemGeneral.name + ' relationship '"
                variant="flat-warning"
                class="button-little-size rounded-circle"
                @click="openAddModalEditRelationshipAdministrator(1,data.item.id,itemGeneral.id)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

            </li>

          </div>

          <div
            v-for="(item, indexCe) in itemGeneral.ce"
            :key="`indexCe-${indexGeneral}-${indexCe}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
          <div
            v-for="(item, indexDs) in itemGeneral.ds"
            :key="`indexDs-${indexGeneral}-${indexDs}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
          <div
            v-for="(item, indexBc) in itemGeneral.bc"
            :key="`indexBc-${indexGeneral}-${indexBc}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
          <div
            v-for="(item, indexSp) in itemGeneral.sp"
            :key="`indexSp-${indexGeneral}-${indexSp}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
        </div>
      </template>
      <template #cell(credit_experts)="data">

        <div
          v-for="(itemGeneral, indexGeneral) in JSON.parse(data.item.ad)"
          :key="`credit_experts-${indexGeneral}`"
        >
          <div v-if="itemGeneral.deleted_at ===null">
            <div style="height: 20px!important;">

              <li
                v-for="(item, indexCe) in itemGeneral.ce"
                :key="`indexCe-${indexGeneral}-${indexCe}`"
              >
                <span>
                  {{ item.name }}
                </span>

                <b-button
                  v-b-tooltip.hover.top="'Edit ' + itemGeneral.name + ' relationship '"
                  variant="flat-warning"
                  class="button-little-size rounded-circle"
                  @click="openAddModalEditRelationshipAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,3, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Delete '+ item.name + ' advisor relationship '"
                  variant="flat-danger"
                  class="button-little-size rounded-circle"
                  @click="deleteRelationAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,3, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </li>

            </div>

            <div
              v-for="(item, indexCe) in itemGeneral.ce"
              :key="`indexCe-${indexGeneral}-${indexCe}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexDs) in itemGeneral.ds"
              :key="`indexDs-${indexGeneral}-${indexDs}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexBc) in itemGeneral.bc"
              :key="`indexBc-${indexGeneral}-${indexBc}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexSp) in itemGeneral.sp"
              :key="`indexSp-${indexGeneral}-${indexSp}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
          </div>
          <div
            v-else
            style="height: 0px"
          />

        </div>
      </template>

      <template #cell(boost_credit)="data">
        <div
          v-for="(itemGeneral, indexGeneral) in JSON.parse(data.item.ad)"
          :key="`boost_credit-${indexGeneral}`"
        >
          <div v-if="itemGeneral.deleted_at ===null">
            <div style="height: 20px!important;">

              <li
                v-for="(item, indexBc) in itemGeneral.bc"
                :key="`indexBc-${indexGeneral}-${indexBc}`"
              >
                <span>
                  {{ item.name }}
                </span>
                <b-button
                  v-b-tooltip.hover.top="'Delete ' + itemGeneral.name + ' relationship '"
                  variant="flat-warning"
                  class="button-little-size rounded-circle"
                  @click="openAddModalEditRelationshipAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,2, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Delete ' + item.name + ' advisor relationship '"
                  variant="flat-danger"
                  class="button-little-size rounded-circle"
                  @click="deleteRelationAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,2, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </li>

            </div>

            <div
              v-for="(item, indexCe) in itemGeneral.ce"
              :key="`indexCe-${indexGeneral}-${indexCe}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexDs) in itemGeneral.ds"
              :key="`indexDs-${indexGeneral}-${indexDs}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexBc) in itemGeneral.bc"
              :key="`indexBc-${indexGeneral}-${indexBc}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexSp) in itemGeneral.sp"
              :key="`indexSp-${indexGeneral}-${indexSp}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
          </div>
          <div
            v-else
            style="height: 0px"
          />

        </div>
      </template>

      <template #cell(debt_solution)="data">
        <div
          v-for="(itemGeneral, indexGeneral) in JSON.parse(data.item.ad)"
          :key="`debt_solution-${indexGeneral}`"
        >
          <div v-if="itemGeneral.deleted_at ===null">
            <div style="height: 20px!important;">

              <li
                v-for="(item, indexDs) in itemGeneral.ds"
                :key="`indexDs-${indexGeneral}-${indexDs}`"
              >
                <span>
                  {{ item.name }}
                </span>
                <b-button
                  v-b-tooltip.hover.top="'Delete ' + itemGeneral.name + ' relationship '"
                  variant="flat-warning"
                  class="button-little-size rounded-circle"
                  @click="openAddModalEditRelationshipAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,4, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Delete '+ item.name + ' advisor relationship '"
                  variant="flat-danger"
                  class="button-little-size rounded-circle"
                  @click="deleteRelationAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,4, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </li>

            </div>

            <div
              v-for="(item, indexCe) in itemGeneral.ce"
              :key="`indexCe-${indexGeneral}-${indexCe}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexDs) in itemGeneral.ds"
              :key="`indexDs-${indexGeneral}-${indexDs}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexBc) in itemGeneral.bc"
              :key="`indexBc-${indexGeneral}-${indexBc}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexSp) in itemGeneral.sp"
              :key="`indexSp-${indexGeneral}-${indexSp}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
          </div>
          <div
            v-else
            style="height: 0px"
          />

        </div>
      </template>
      <template #cell(specialistType)="data">
        <div
          v-for="(itemGeneral, indexGeneral) in JSON.parse(data.item.ad)"
          :key="`specialistType-${indexGeneral}`"
        >
          <div v-if="itemGeneral.deleted_at ===null">
            <div style="height: 20px!important;">

              <li
                v-for="(item, indexSp) in itemGeneral.sp"
                :key="`indexSp-${indexGeneral}-${indexSp}`"
              >
                <span>
                  {{ item.name }}
                </span>
                <b-button
                  v-b-tooltip.hover.top="'Delete ' + itemGeneral.name + ' relationship '"
                  variant="flat-warning"
                  class="button-little-size rounded-circle"
                  @click="openAddModalEditRelationshipAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,7, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Delete ' + item.name + ' advisor relationship '"
                  variant="flat-danger"
                  class="button-little-size rounded-circle"
                  @click="deleteRelationAdvisor(item.id, item.name,item.advisor_id,data.item.specialist_id,7, itemGeneral.administrator_id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </li>

            </div>

            <div
              v-for="(item, indexCe) in itemGeneral.ce"
              :key="`indexCe-${indexGeneral}-${indexCe}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexDs) in itemGeneral.ds"
              :key="`indexDs-${indexGeneral}-${indexDs}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexBc) in itemGeneral.bc"
              :key="`indexBc-${indexGeneral}-${indexBc}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
            <div
              v-for="(item, indexSp) in itemGeneral.sp"
              :key="`indexSp-${indexGeneral}-${indexSp}`"
              style="height: 20px!important;"
            >
              <span class="m-1" />
            </div>
          </div>
          <div
            v-else
            style="height: 0px"
          />

        </div>
      </template>

      <template #cell(actions)="data">

        <div
          v-for="(itemGeneral, indexGeneral) in JSON.parse(data.item.ad)"
          :key="`actions-${indexGeneral}`"
        >

          <div
            v-if="itemGeneral.deleted_at ===null"
            style="height: 20px!important;"
          >

            <span class="m-1">
              <b-button
                v-if="itemGeneral.ce ===null && itemGeneral.ds ===null && itemGeneral.bc ===null && itemGeneral.sp ===null"
                v-b-tooltip.hover.top="'Delete ' + itemGeneral.name + ' relationship '"
                variant="flat-danger"
                class="button-little-size rounded-circle"
                @click="deleteRelationAdministraton(itemGeneral.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>

          </div>

          <div
            v-for="(item, indexCe) in itemGeneral.ce"
            :key="`indexCe-${indexGeneral}-${indexCe}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
          <div
            v-for="(item, indexDs) in itemGeneral.ds"
            :key="`indexDs-${indexGeneral}-${indexDs}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
          <div
            v-for="(item, indexBc) in itemGeneral.bc"
            :key="`indexBc-${indexGeneral}-${indexBc}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
          <div
            v-for="(item, indexSp) in itemGeneral.sp"
            :key="`indexSp-${indexGeneral}-${indexSp}`"
            style="height: 20px!important;"
          >
            <span class="m-1" />
          </div>
        </div>

      </template>
    </b-table>
    <modal-add-relationship
      v-if="modalRelationship"
      @closeModal="closeModalRelationship"
    />
    <modal-edit-relationship
      v-if="modalEditRelationship"
      :type-modal="typeModal"
      :user="specialistId"
      :relation-id="relationId"
      :specialist-user-id="specialistUserId"
      :program-id="programId"
      :administrator-id="administratorId"
      @refresh="myProvider"
      @closeModal="closeModalEditRelationship"
    />
    <modal-clients-assigned-relation
      v-if="modalAssignedRelation"
      :advisor-name="advisorName"
      :advisor-id="advisorId"
      :program-id="programId"
      :administrator-id="administratorId"
      :relation-id="relationId"
      :type-modal="typeModal"
      @reload="myProvider"
      @close="modalAssignedRelation = false"
    />
  </div>
</template>

<script>

import ModalAddRelationship from '@/views/administration/views/settings/components/ModalAddRelationship.vue'

import SettingsRelationService from '@/views/administration/views/settings/service/settings-relation.service'
import ModalEditRelationship from '@/views/administration/views/settings/components/ModalEditRelationship.vue'
import { mapGetters } from 'vuex'
import { swalWarningIcon } from '@/icons/statusIcons'
import ModalClientsAssignedRelation from '@/views/administration/views/settings/components/ModalClientsAssignedRelation.vue'

export default {
  components: { ModalAddRelationship, ModalEditRelationship, ModalClientsAssignedRelation },
  data() {
    return {
      advisorId: null,
      modalAssignedRelation: false,
      advisorName: '',
      administratorId: null,
      programId: null,
      specialistUserId: null,
      relationId: null,
      specialistId: null,
      typeModal: null,
      modalEditRelationship: false,
      modalRelationship: false,
      ad: [],
      fields: [
        {
          key: 'specialist',
          label: 'Specialist',
        },
        {
          key: 'administrator',
          label: 'Administrator',
        },
        {
          key: 'credit_experts',
          label: 'Credit Experts',
        },
        {
          key: 'boost_credit',
          label: 'Boost Credit',
        },
        {
          key: 'debt_solution',
          label: 'Debt Solution',
        },
        {
          key: 'specialistType',
          label: 'Specialists',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      createClientModal: false,
      createEditModal: false,
      show: false,

      startPage: null,
      toPage: null,
      isBusy: false,
      items: [],
      order: 'desc',
      sortBy: 'created_at',
      modalClientsAssignedState: false,
      dataItem: {},

      information: [],
    }
  },
  computed: {
    ...mapGetters({

      currentUser: 'auth/currentUser',

    }),

  },
  async created() {
    await this.myProvider()
  },

  methods: {
    showConfirmSwalForUsers(
      title = 'Are you sure?',
      text = 'You won\'t be able to revert this!',
      config = {},
    ) {
      return this.$swal({
        title,
        text,
        imageUrl: swalWarningIcon,
        imageWidth: 70,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: 'Assign Now',
        customClass: {
          confirmButton: 'btn btn-primary mr-1',
          cancelButton: 'btn btn-outline-danger',
          denyButton: 'btn btn-info mr-1',
        },
        ...config,
      })
    },
    async deleteRelationAdvisorasd(relationId) {
      // try catch
      try {
        const result = await this.showConfirmSwal()
        if (result.isConfirmed) {
          const params = {
            id_relationship: relationId,
            id_user: this.currentUser.user_id,
          };
          const response = await SettingsRelationService.deleteRelationAdvisor(params)
          if (response.status === 200) {
            this.$bvToast.toast('Relationship deleted successfully', {
              title: 'Success',
              variant: 'success',
              solid: true,
            })
            await this.myProvider()
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteRelationAdministraton(relationId) {
      // try catch
      try {
        const result = await this.showConfirmSwal()
        if (result.isConfirmed) {
          const params = {
            id_relationship: relationId,
            id_user: this.currentUser.user_id,
          }
          const response = await SettingsRelationService.deleteRelationAdministrator(params)
          if (response.status === 200) {
            this.$bvToast.toast('Relationship deleted successfully', {
              title: 'Success',
              variant: 'success',
              solid: true,
            })
            await this.myProvider()
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    async deleteRelationAdvisor(relationId, advisorName, advisorId, specialistUserId, programId, administratorId) {
      // try catch
      try {
        const result = await this.showConfirmSwalForUsers(
          'There are assigned clients to this advisor. Please select another advisor to reassign them to delete the advisor.',
          'You have to do it to be able to remove the advisor',
        )
        if (result.isConfirmed) {
          this.typeModal = 2
          this.advisorName = advisorName
          this.advisorId = advisorId
          this.specialistUserId = specialistUserId
          this.programId = programId
          this.administratorId = administratorId
          this.relationId = relationId
          this.modalAssignedRelation = true
        }
      } catch (error) {
        console.log(error)
      }
    },

    openAddModalRelationship() {
      this.modalRelationship = true
    },
    closeModalRelationship() {
      this.modalRelationship = false
    },
    openAddModalEditRelationshipAdministrator(typeModal, specialistId, relationId) {
      this.typeModal = typeModal
      this.specialistId = specialistId
      this.relationId = relationId
      this.modalEditRelationship = true
    },
    openAddModalEditRelationshipAdvisor(relationId, advisorName, advisorId, specialistUserId, programId, administratorId) {
      this.typeModal = 1
      this.advisorName = advisorName
      this.advisorId = advisorId
      this.specialistUserId = specialistUserId
      this.programId = programId
      this.administratorId = administratorId
      this.relationId = relationId

      this.modalAssignedRelation = true
    },
    closeModalEditRelationship() {
      this.modalEditRelationship = false
    },
    async myProvider() {
      try {
        const params = {}
        const data = await SettingsRelationService.getAdministrationSettings(params)
        if (data.status === 200) {
          this.information = data.data
          return this.information
        }
      } catch (e) {
        return []
      }
    },
  },

}
</script>

<style>

.predeName{
  width: 100px!important;
}

</style>
