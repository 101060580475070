import { amgApi } from "@/service/axios";

class UsersService {

    async getAllUsers(params){
        const data = await amgApi.post("credit-experts-digital/get-all-creditexpert-setting-user",params)
        return data
    }

    async getAllModules(){
        const data = await amgApi.post("credit-experts-digital/get-modules-for-creditexpert")
        return data.data
    }

    async getAllRolesForModule(params){
        const data = await amgApi.post("credit-experts-digital/get-all-roles-for-module",params)
        return data.data
    }

    async saveModuleAndRole(params){
        const data = await amgApi.post("credit-experts-digital/save-module-and-role",params)
        return data
    }

    async getAllModuleAndRoleForUser(params){
        const data = await amgApi.post("credit-experts-digital/get-all-module-and-role-for-user",params)
        return data
    }

    async deleteRowUserModule(params){
        const data = await amgApi.post("credit-experts-digital/delete-row-user-module",params)
        return data
    }

    async getAllTrackingForUser(params){
        const data = await amgApi.post("credit-experts-digital/get-all-tracking-for-user",params)
        return data
    }

    async getClientsForAdvisor(params){
        const data = await amgApi.post("credit-experts-digital/get-clients-for-advisor",params)
        return data
    }

    async getAllAdvisorForCreditExpert(params){
        const data = await amgApi.post("credit-experts-digital/get-all-advisor-for-credit-expert",params)
        return data.data
    }

    async updateClientForAdvisor(params){
        const data = await amgApi.post("credit-experts-digital/update-client-for-advisor",params)
        return data.data
    }

    async getCountClientsOfAdvisor(params){
        const data = await amgApi.post("credit-experts-digital/get-count-clients-of-advisor",params)
        return data.data
    }

    async updateStatusOfAdvisor(params){
        const data = await amgApi.post("credit-experts-digital/update-status-of-advisor",params)
        return data
    }

    async getAllTrackingStatusForUser(params){
        const data = await amgApi.post("credit-experts-digital/get-all-tracking-status-for-user",params)
        return data
    }

    async getAllTrackingAsignClientsAdvisor(params){
        const data = await amgApi.post("credit-experts-digital/get-all-tracking-asign-clients-advisor",params)
        return data
    }
}

export default new UsersService()