<template>
  <b-modal
    v-model="modalcenter"
    ref="reassignAdvisorModal"
    modal
    size="xmd"
    header-class="p-0"
    no-close-on-backdrop
    header-bg-variant="transparent border-bottom border-bottom-2"
    modal-class="modal-primary"

    scrollable
    @hidden="hideModal"
  >
    <template v-slot:modal-header>
      <template>
        <div class="w-100">
          <b-button-close
            style="transform: translate(-12px, 4px)"
            @click="hideModal"
          />
          <b-container fluid>
            <b-row class="text-center bg-primary px-3 py-1">
              <h3 class="font-weight-bolder text-white">
                Reassign Advisor
              </h3>
            </b-row>
            <b-row class="mt-1 px-2">
              <b-col lg="6">
                <h5>ADVISOR</h5>
                <p
                  class="rounded text-primary border-primary font-medium-1 text-center py10"
                >
                  {{ advisorName | myFontCapitalize }}
                </p>
              </b-col>
              <b-col lg="6">    <validation-observer ref="form">
                <b-row style="margin-left: 15px">
                  <b-col cols="12">
                    <b-row>
                      <b-form-group
                        label="Select advisor"
                        style="width: 100% !important"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="module"
                          rules="required"
                        >
                          <b-select
                            v-model="select_advisor"
                            :options="optionsAdvisor"
                            value-field="id"
                            text-field="user_name"
                            class="w-full { 'is-border-danger': errors[0] }"
                            :state="errors[0] ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>

                    </b-row>
                  </b-col>
                </b-row>
              </validation-observer></b-col>
            </b-row>
          </b-container>
        </div>
      </template>
    </template>

    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filter"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refsTable"
          small
          no-provider-filtering
          :items="init"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="overflow-y"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(created_at)="data">
            <div>
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <template #modal-footer>
      <b-button
        variant="primary"

        @click="assign()"
      >
        {{ typeModal==1? 'Assign and edit selected advisor': 'Assign and delete selected advisor' }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>

import { mapGetters } from 'vuex'
import SettingsRelationService from '@/views/administration/views/settings/service/settings-relation.service'
import UsersService from '@/views/ce-digital/sub-modules/settings/views/users/service/users.service.js'

export default {

  props: {
    advisorName: {
      type: String,
      default: '',
    },
    advisorId: {
      type: Number,
      default: null,
    },

    relationId: {
      type: Number,
      default: null,
    },
    programId: {
      type: Number,
      default: null,
    },
    administratorId: {
      type: Number,
      default: null,
    },

    fromUsersModule: {
      type: Boolean,
      default: false,
    },
    typeModal: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      allClients: [],
      clients_status: [],
      selectAll: null,
      clients: [],
      select_advisor: null,
      optionsAdvisor: [
        {
          value: 'All',
          id: null,
        },
      ],
      filter: [],
      modalcenter: false,
      recoveryMood: false,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      sortBy: 'created_at',
      sortDesc: true,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,

      fields: [
        {
          key: 'lead_name',
          label: 'Name',
          class: 'text-left',
          sortable: true,
          visible: true,
        },
        {
          key: 'account',
          label: 'account',
          visible: true,
        },

        {
          key: 'email',
          label: 'Email',
          visible: true,
        },
        {
          key: 'mobile',
          label: 'Mobile',
          visible: true,
        },
        {
          key: 'created_at',
          label: 'Creation date',
          visible: true,
          sortable: true,
        },
      ],
    }
  },
  async created() {
    this.modalcenter = true
    if (this.typeModal == 1) {
      await this.selectProgram()
    } else if (this.typeModal == 2) {
      await this.selectAdvisorWithRelation()
    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  methods: {
    async deleteRelationAdvisor() {
      // try catch
      try {
        const params = {
          id_relationship: this.relationId,
          id_user: this.currentUser.user_id,
        }
        const response = await SettingsRelationService.deleteRelationAdvisor(params)
        if (response.status === 200) {
          this.$bvToast.toast('Relationship deleted successfully', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    async selectAdvisorWithRelation() {
      try {
        const body = {
          id_program: this.programId,
        }
        const res = await SettingsRelationService.listAdvisorWithRelationship(body)
        if (res.status === 200) {
          this.optionsAdvisor = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async saveRelationshipAdvisor() {
      try {
        const data = {
          id_relation: this.relationId,
          id_advisor: this.select_advisor,
        }

        const response = await SettingsRelationService.editRelationAdvisor(data)
        if (response.status === 200) {
          this.$swal({
            title: 'Success',
            text: 'Relationship edited successfully',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          })
        }
      } catch (error) {
        console.log('error', error)
        this.$swal({
          title: 'Error',
          text: 'Error edited relationship',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        })
      }
    },
    async assign() {
      console.log('assign')
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const confirm = await this.showConfirmSwal(
            'You want to assign the clients to the selected advisor',
          )

          if (confirm.value) {
            const params = {
              advisor_id: this.select_advisor,
              user_id: this.advisorId,

            }

            const response = await SettingsRelationService.updateAdvisorInClientAccount(params)
            if (response.status === 200) {
              this.selectAll = null
              if (this.typeModal === 1) {
                await this.saveRelationshipAdvisor()
                this.$emit('reload')
                this.removePreloader()
                this.hideModal()
              } else if (this.typeModal === 2) {
                await this.deleteRelationAdvisor()
                this.$emit('reload')
                this.removePreloader()
                this.hideModal()
              }
            }
          }
        }
      } catch (e) {
        console.error(e)
        this.removePreloader()
        this.showToast(
          'danger',
          'top-right',
          'Error',
          'XIcon',
          'Something went wrong!',
        )
      }
    },
    async selectProgram() {
      try {
        const body = {
          id_program: this.programId,
          id_specialist: this.specialistUserId,
          id_administrator: this.administratorId,
        }
        const res = await SettingsRelationService.listAdvisor(body)
        if (res.status === 200) {
          this.optionsAdvisor = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    refresh() {
      this.$refs.refsTable.refresh()
    },
    hideModal() {
      this.$refs['reassignAdvisorModal'].hide();
      this.$emit('close')
    },

    async init(ctx) {
      let sort_By = 1
      let sortDirection = 'desc'
      if (ctx) {
        if (ctx.sortBy === 'created_at') {
          sort_By = 5
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
        }
      }
      try {
        const params = {
          user_id: this.advisorId,
          texto: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          orderby: sort_By,
          order: sortDirection,
        }
        const response = await UsersService.getClientsForAdvisor(params)
        this.startPage = response.data.from
        this.paginate.currentPage = response.data.current_page
        this.paginate.perPage = response.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = response.data.last_page
        this.totalRows = response.data.total
        this.toPage = response.data.to
        this.items = response.data.data
        this.clients = response.data.data
        return response.data.data
      } catch (e) {
        console.error(e)
        this.showErrorSwal(e)
      }
    },
  },
}
</script>
<style scoped></style>
