<template>
  <b-modal
    ref="refAddRelationshipModal"
    v-model="ownModal"
    title="EDIT RELATIONSHIP"
    size="sm"
    centered
    :no-close-on-backdrop="true"
    @hidden="closeModal"
  >
    <validation-observer ref="form">
      <b-row>

        <b-col
          v-if="typeModal===1"
        >

          <label>ADMINISTRATOR:</label>
          <validation-provider
            v-slot="{ errors }"
            name="Select"
            rules="required"
          >
            <b-select
              v-model="administrator"
              :options="administrators"
              value-field="id"
              text-field="user_name"
              @change="selectAdministrator"
            />
            <label
              v-if="errors[0]"
              class="text-danger"
            >
              Select {{ errors[0] }}
            </label>
          </validation-provider>

        </b-col>

        <b-col
          v-if="typeModal===2"
        >

          <label>ADVISOR:</label>
          <validation-provider
            v-slot="{ errors }"
            name="Select"
            rules="required"
          >
            <b-select
              v-model="advisor"
              :options="advisors"
              value-field="id"
              text-field="user_name"
            />
            <label
              v-if="errors[0]"
              class="text-danger"
            >
              Select {{ errors[0] }}
            </label>
          </validation-provider>

        </b-col>

      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-button
        v-if="typeModal===1"
        variant="primary"
        @click="saveRelationshipAdministrator"
      >
        Save
      </b-button>
      <b-button
        v-if="typeModal===2"
        variant="primary"
        @click="saveRelationshipAdvisor"
      >
        Save
      </b-button>
      <b-button
        variant="secondary"
        @click="closeModal"
      >
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'

import SettingsRelationService from '@/views/administration/views/settings/service/settings-relation.service'
import ClientDashboardService from '@/views/commons/components/clients/dashboard/services/clients.dashboard.services'

export default {
  props: {
    typeModal: {
      type: Number,
      default: null,
    },
    user: {
      type: Number,
      default: null,
    },
    relationId: {
      type: Number,
      default: null,
    },
    programId: {
      type: Number,
      default: null,
    },
    administratorId: {
      type: Number,
      default: null,
    },
    specialistUserId: {
      type: Number,
      default: null,
    },

  },

  data() {
    return {
      ownModal: true,

      administrator: null,
      program: null,
      advisor: null,
      users: [],
      administrators: [],
      programs: [],
      advisors: [],

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },
  async created() {
    if (this.typeModal === 1) {
      await this.userssupervisor()
    }
    if (this.typeModal === 2) {
      await this.selectProgram()
    }
  },

  methods: {

    // save with swall of are you sure and try and catch
    async saveRelationshipAdministrator() {
      try {
        const data = {
          id_relation: this.relationId,
          id_administrator: this.administrator,
        }

        const response = await SettingsRelationService.saveRelationAdministrator(data)
        if (response.status === 200) {
          this.$swal({
            title: 'Success',
            text: 'Relationship edited successfully',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          })
          this.$emit('refresh')
          this.closeModal()
        }

        this.$emit('closeModal')
      } catch (error) {
        console.log('error', error)
        this.$swal({
          title: 'Error',
          text: 'Error edited relationship',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        })
      }
    },

    // save with swall of are you sure and try and catch
    async saveRelationshipAdvisor() {
      try {
        const data = {
          id_relation: this.relationId,
          id_advisor: this.advisor,
        }

        const response = await SettingsRelationService.editRelationAdvisor(data)
        if (response.status === 200) {
          this.$swal({
            title: 'Success',
            text: 'Relationship edited successfully',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          })
          this.$emit('refresh')
          this.closeModal()
        }

        this.$emit('closeModal')
      } catch (error) {
        console.log('error', error)
        this.$swal({
          title: 'Error',
          text: 'Error edited relationship',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        })
      }
    },

    // userssupervisor
    async userssupervisor() {
      try {
        const body = {
          id_specialist: this.user,
          id_type: 2,
        }
        const res = await SettingsRelationService.getUserSupervisor(body)
        if (res.status === 200) {
          this.administrators = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    // async select program
    async selectProgram() {
      try {
        const body = {
          id_program: this.programId,
          id_specialist: this.specialistUserId,
          id_administrator: this.administratorId,
        }
        const res = await SettingsRelationService.listAdvisor(body)
        if (res.status === 200) {
          this.advisors = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // selectAdministrator
    async selectAdministrator() {
      try {
        const body = {
          id_specialist: this.user,
          id_administrator: this.administrator,
        }
        const res = await SettingsRelationService.listProgram(body)
        if (res.status === 200) {
          this.programs = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // allOwners
    async getUserModule() {
      try {
        const data = await ClientDashboardService.userModule(11, {
          roles: '[2,3]',
          type: '1',
        })

        this.users = data.data
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
