import { amgApi } from '@/service/axios'

class SettingsRelationService {
  async getUserSupervisor(params) {
    const data = await amgApi.post('/administration/users-supervisor', params)
    return data
  }

  async saveRelationship(params) {
    const data = await amgApi.post('/administration/save-relationship', params)
    return data
  }

  async listAdvisor(params) {
    const data = await amgApi.post('/administration/list-advisor', params)
    return data
  }

  async listProgram(params) {
    const data = await amgApi.post('/administration/list-program', params)
    return data
  }

  // get-administration-settings
  async getAdministrationSettings(params) {
    const data = await amgApi.post('/administration/get-administration-settings', params)
    return data
  }

  // delete-relation-advisor
  async deleteRelationAdvisor(params) {
    const data = await amgApi.post('/administration/delete-relation-advisor', params)
    return data
  }

  // delete-relation-administrator
  async deleteRelationAdministrator(params) {
    const data = await amgApi.post('/administration/delete-relation-administrator', params)
    return data
  }

  // save-relation-administrator
  async saveRelationAdministrator(params) {
    const data = await amgApi.post('/administration/save-relation-administrator', params)
    return data
  }

  //edit-relation-advisor
    async editRelationAdvisor(params) {
        const data = await amgApi.post('/administration/edit-relation-advisor', params)
        return data
    }

    //update-advisor-in-client-account
    async updateAdvisorInClientAccount(params) {
        const data = await amgApi.post('/administration/update-advisor-in-client-account', params)
        return data
    }

    //list-advisor-with-relationship

    async listAdvisorWithRelationship(params) {
        const data = await amgApi.post('/administration/list-advisor-with-relationship', params)
        return data
    }
}

export default new SettingsRelationService()
