var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refAddRelationshipModal",attrs:{"title":"REGISTER RELATIONSHIP","size":"lg","no-close-on-backdrop":true},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveRelationship}},[_vm._v(" Save ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")])]},proxy:true}]),model:{value:(_vm.ownModal),callback:function ($$v) {_vm.ownModal=$$v},expression:"ownModal"}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("SPECIALIST:")]),_c('validation-provider',{attrs:{"name":"Select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.users,"value-field":"id","text-field":"user_name"},on:{"change":_vm.userssupervisor},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),(errors[0])?_c('label',{staticClass:"text-danger"},[_vm._v(" Select "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("ADMINISTRATOR:")]),_c('validation-provider',{attrs:{"name":"Select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.administrators,"value-field":"id","text-field":"user_name"},on:{"change":_vm.selectAdministrator},model:{value:(_vm.administrator),callback:function ($$v) {_vm.administrator=$$v},expression:"administrator"}}),(errors[0])?_c('label',{staticClass:"text-danger"},[_vm._v(" Select "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("PROGRAM:")]),_c('validation-provider',{attrs:{"name":"Select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.programs,"value-field":"id","text-field":"value"},on:{"change":_vm.selectProgram},model:{value:(_vm.program),callback:function ($$v) {_vm.program=$$v},expression:"program"}}),(errors[0])?_c('label',{staticClass:"text-danger"},[_vm._v(" Select "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("ADVISOR:")]),_c('validation-provider',{attrs:{"name":"Select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.advisors,"value-field":"id","text-field":"user_name"},model:{value:(_vm.advisor),callback:function ($$v) {_vm.advisor=$$v},expression:"advisor"}}),(errors[0])?_c('label',{staticClass:"text-danger"},[_vm._v(" Select "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }