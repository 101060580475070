<template>
  <b-modal
    ref="refAddRelationshipModal"
    v-model="ownModal"
    title="REGISTER RELATIONSHIP"
    size="lg"
    :no-close-on-backdrop="true"
    @hidden="closeModal"
  >
    <validation-observer ref="form">
      <b-row>

        <b-col md="3">

          <label>SPECIALIST:</label>
          <validation-provider
            v-slot="{ errors }"
            name="Select"
            rules="required"
          >
            <b-select
              v-model="user"
              :options="users"
              value-field="id"
              text-field="user_name"
              @change="userssupervisor"
            />
            <label
              v-if="errors[0]"
              class="text-danger"
            >
              Select {{ errors[0] }}
            </label>
          </validation-provider>

        </b-col>

        <b-col md="3">

          <label>ADMINISTRATOR:</label>
          <validation-provider
            v-slot="{ errors }"
            name="Select"
            rules="required"
          >
            <b-select
              v-model="administrator"
              :options="administrators"
              value-field="id"
              text-field="user_name"
              @change="selectAdministrator"
            />
            <label
              v-if="errors[0]"
              class="text-danger"
            >
              Select {{ errors[0] }}
            </label>
          </validation-provider>

        </b-col>
        <b-col md="3">

          <label>PROGRAM:</label>
          <validation-provider
            v-slot="{ errors }"
            name="Select"
            rules="required"
          >
            <b-select
              v-model="program"
              :options="programs"
              value-field="id"
              text-field="value"
              @change="selectProgram"
            />
            <label
              v-if="errors[0]"
              class="text-danger"
            >
              Select {{ errors[0] }}
            </label>
          </validation-provider>

        </b-col>

        <b-col md="3">

          <label>ADVISOR:</label>
          <validation-provider
            v-slot="{ errors }"
            name="Select"
            rules="required"
          >
            <b-select
              v-model="advisor"
              :options="advisors"
              value-field="id"
              text-field="user_name"
            />
            <label
              v-if="errors[0]"
              class="text-danger"
            >
              Select {{ errors[0] }}
            </label>
          </validation-provider>

        </b-col>

      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="saveRelationship"
      >
        Save
      </b-button>
      <b-button
        variant="secondary"
        @click="closeModal"
      >
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'

import SettingsRelationService from '@/views/administration/views/settings/service/settings-relation.service'
import MigrationsService from '@/views/social-network/views/migrations/migrations.service'
import ClientDashboardService from '@/views/commons/components/clients/dashboard/services/clients.dashboard.services'

export default {

  data() {
    return {
      ownModal: true,
      user: null,
      administrator: null,
      program: null,
      advisor: null,
      users: [],
      administrators: [],
      programs: [],
      advisors: [],

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },
  async created() {
    await this.getUserModule()
  },

  methods: {

    // save with swall of are you sure and try and catch
    async saveRelationship() {
      try {
        const data = {
          id_specialist: this.user,
          id_administrator: this.administrator,
          id_advisor: this.advisor,
          id_program: this.program,
        }

        const response = await SettingsRelationService.saveRelationship(data)
        if (response.status === 200) {
          this.$swal({
            title: 'Success',
            text: 'Relationship created successfully',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          })
          this.$emit('refresh')
          this.closeModal()
        }

        this.$emit('closeModal')
      } catch (error) {
        console.log('error', error)
        this.$swal({
          title: 'Error',
          text: 'Error creating relationship',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        })
      }
    },

    // userssupervisor
    async userssupervisor() {
      try {
        const body = {
          id_specialist: this.user,
          id_type: 1,
        }
        const res = await SettingsRelationService.getUserSupervisor(body)
        if (res.status === 200) {
          this.administrators = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    // async select program
    async selectProgram() {
      try {
        const body = {
          id_program: this.program,
          id_specialist: this.user,
          id_administrator: this.administrator,
        }
        const res = await SettingsRelationService.listAdvisor(body)
        if (res.status === 200) {
          this.advisors = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // selectAdministrator
    async selectAdministrator() {
      try {
        const body = {
          id_specialist: this.user,
          id_administrator: this.administrator,
        }
        const res = await SettingsRelationService.listProgram(body)
        if (res.status === 200) {
          this.programs = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // allOwners
    async getUserModule() {
      try {
        const data = await ClientDashboardService.userModule(11, {
          roles: '[2,3]',
          type: '1',
        })

        this.users = data.data
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
